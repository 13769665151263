nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
  position: relative;

  img {
    max-width: 200px;
    height: auto;
		max-height: 50px;

    cursor: pointer;
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    .notification {
      position: relative;
    }
    li {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      cursor: pointer;

      .number-container {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: red;
        width: 15px;
        height: 15px;
        aspect-ratio: 1;
        border-radius: 50%;
        color: white;
        top: 0px;
        right: 0px;

        span {
          font-weight: bolder;
        }
      }

      &.user {
        font-size: 1.4rem;
        gap: 7px;
        font-weight: 600;
        img {
          width: 50px;
          height: 50px;
          object-fit: cover;
        }
      }
    }
  }

  .sub-menu-options {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translate(-25%, 100%);
    z-index: 1;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    max-height: 0px;
    transition: 0.4s ease max-height;

    div {
      padding: 10px;
      font-size: 1.4rem;
      border-bottom: 2px solid #0000001a;
    }

    &.open {
      max-height: 240px;
    }
  }

  @media (max-width: 992px) {
    ul {
      li.user {
        span {
          display: none;
        }
      }
    }
  }
}
