.my-classes-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  * {
    margin: 0;
  }
  background-color: #ffffff;
  border-radius: 10px;
  flex-grow: 1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  overflow: hidden;

  .day-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .header-day-container {
      padding: 10px;
      text-align: center;
      color: #ffffff;
      background-color: #153642;
    }

    .classes-cards-container {
      padding: 10px 20px;
    }
  }
}
