.reset-password-main-container {
  display: flex;
  justify-content: center;
  align-items: center;

  form.reset-password {
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 1.4rem;
    border-radius: 7px;
    background-color: #ffffff;
    width: 50%;
    min-width: 300px;
    padding: 20px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);

    .c-row {
      display: flex;
      flex-direction: column;
      gap: 5px;
      
    }
  }
}
