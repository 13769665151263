.available-classes-main-container {
  display: flex;
  border-radius: 10px;
  background-color: #ffffff;
  flex-grow: 1;

  .location-filter-container {
    width: 200px;
    min-width: 200px;
    max-width: 200px;
    border-right: 2px solid #f3f3f3;
    padding-top: 84px;

    li {
      font-size: 1.6rem;
      text-align: right;
      padding: 5px;
      color: #7e7986;
      user-select: none;

      &.active {
        color: #ffffff;
        background-color: #eb787a;
      }
    }
  }

  .calendary-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    padding: 20px;

    .calendar-nav {
      display: flex;
      justify-content: center;
      align-items: center;
      user-select: none;

      .custom-dp {
        width: 150px;
        * {
          border: none;

        }
        font-size: 1.4rem;

        .react-date-picker__inputGroup {
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;

          input, span {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            flex-grow: 1;
          }
        }
        .react-date-picker__calendar-button {
          display: none;
        }
      }
      h2 {
        margin: 0;
      }
    }
    .classes-container {
      width: 100%;
      background-color: #f7f7f7;
      padding: 20px;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-content: flex-start;
      gap: 10px;
    }
  }

  @media (max-width: 991px) {
    flex-direction: column;

    .location-filter-container {
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      max-width: none;
      padding-top: 10px;
      display: flex;
      flex-direction: column;
      height: 44px;
      overflow: hidden;

      &.isOpen {
        height: fit-content;

      }
      li {
        width: 100%;
        text-align: center;
        order: 2;


        &.active {
          order: 1;
        }
      }
    }

    .calendary-container {
      padding: 5px;

      .classes-container {
        justify-content: center;
        padding: 10px 5px;
      }
    }
  }
}
