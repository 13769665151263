.available-class-card-main-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 48%;
  min-width: 250px;
  padding-bottom: 20px;
  border-bottom: 2px solid #e1e1e1;

  .info-container,
  .actions-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .info-container {
    h3 {
      font-size: 1.6rem;
      font-weight: 600;
      span {
        font-size: 1.2rem;
        border-radius: 4px;
        padding: 1px 4px;
        font-weight: 500;

        &.available {
          background-color: #00a87e;
          color: #ffffff;
        }
        &.full {
          background-color: #cd5f62;
          color: #ffffff;
        }
      }
    }

    h4 {
      font-size: 1.4rem;
      font-style: italic;
      color: #7e7986;
    }
  }

  .actions-container {
    h4 {
      font-size: 1.2rem;
      text-align: right;
    }
    button {
      margin-left: auto;

      &.btn {
        font-size: 1.2rem;
      }
    }
  }

  @media (max-width: 991px) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .info-container {
      h3 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
      }
    }

    .actions-container {
      button {
        margin: 0;
      }
    }
  }
}
