.header-main-container {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  background-color: #ffffff;

  .cover {
    height: 100px;
    background-color: #153442;
  }

  .profile-information {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 20px;
    padding: 20px;
    margin-top: -40px;

    .avatar-container {
      width: 120px;
      height: 120px;
      min-width: 120px;
      border-radius: 5px;
      border: 4px solid #ffffff;
      background-color: #f8f7fa;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .user-information {
      display: flex;
      flex-direction: column;
      gap: 20px;

      h3 {
        font-size: 1.6rem;

        &.name {
          font-size: 2rem;
        }
      }

      .data-container {
        display: flex;
        gap: 20px;

        align-items: flex-end;

        h3 {
          line-height: 1.6rem;
          margin: 0;
          svg {
            width: 1.6rem;
          }
        }
      }
    }

    button {
      margin-left: auto;
      width: 150px;
    }
  }

  @media (max-width: 991px) {
    display: none;

    &.active { 
      display: block;
    }
    .profile-information {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .user-information {
        justify-content: center;
        align-items: center;

        .data-container {
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
