ul.schedules-history-selector {
	display: flex;
	align-items: center;
	width: 100%;
	margin: 20px 0px;

	li {
		flex: 1;
		padding: 5px;
		text-align: center;
		user-select: none;
		border-bottom: 4px solid transparent;
		font-size: 1.8rem;

		&.active {
			border-color: #7367f0;
		}
	}

	@media (max-width: 992px) {
		li {
			font-size: 1.4rem;
		}
	}
}
