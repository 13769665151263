.spinner-custom-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  .spinner-border {
    width: 120px;
    height: 120px;
    border: 8px solid #eb787a;
    border-right-color: transparent;
    span {
      display: none;
    }
  }
}
