.date-waiting-list-container {
  width: 100%;
  color: #f1f1f1;
  background-color: #153442;
  text-align: center;
  padding: 5px 10px;
}
.class-card-component {
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #f1f1f1;
  padding: 0px 20px;

  .image-container {
    width: 100%;
    max-width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;

    svg, img {
      width: 100%;
      height: auto;
      aspect-ratio: 20/18;
    }
  }

  .info-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;

    h4 {
      font-size: 1.2rem;
      font-weight: bolder;
      color: #566779;
    }

    h2 {
      font-size: 1.8rem;
      margin: 10px 0px;
    }

    h3 {
      font-size: 1.2rem;
    }
  }

  .actions-container {
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }

  @media (max-width: 991px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding-bottom: 10px;

    .actions-container {
      align-items: center;
    }
  }
}