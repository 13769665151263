.user-additional-information-card {
	border-radius: 7px;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
	padding: 20px;
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	gap: 30px;
	min-width: 300px;
	width: 100%;
	max-width: 450px;
	height: fit-content;

	.general-info-container,
	.info-container {
		display: flex;
		flex-direction: column;
		gap: 10px;

		.row {
			margin: 0;

			p,
			h3 {
				margin: 0;
				font-size: 1.4rem;
			}
		}
	}

	.info-container {
		display: grid;
		grid-template-columns: 1fr auto;
		align-items: start;
	}
}
