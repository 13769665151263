.empty-container-component-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  color: #eb787a;

  svg {
    width: 80px;
    height: 80px;
    stroke: #eb787a;
  }
}
