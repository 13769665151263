.purchase-page-main-container {
  flex-grow: 1;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border-radius: 10px;

  .purchase-header-steps-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 60px;
    padding: 20px;
    border-bottom: 2px solid #f3f3f3;

    .step-container {
      p {
        margin: 0;
        font-size: 1.4rem;
        font-weight: bolder;
      }

      &.active {
        color: #eb787a;
      }
    }
    svg.active {
      stroke: #eb787a;
    }

    @media(max-width: 992px) {
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 10px;

      svg.arrow {
        transform: rotate(90deg);
      }
    }
  }
}
