.memberships-cards-container-component {
	border-radius: 7px;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
	padding: 20px;
	background-color: #ffffff;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	gap: 20px;

	ul.memberships-nav {
		display: flex;
		width: 100%;

		li {
			flex: 1;
			text-align: center;
			padding: 10px 5px;
			font-size: 2rem;
			border-bottom: 4px solid transparent;
			font-weight: 500;

			&.active {
				border-color: #7367f0;
			}
		}
	}

	.row.header {
		margin: 0;
		> * {
			width: auto;
			margin: 0;
		}
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		h2 {
			padding: 0;
			font-size: 1.6rem;
			font-weight: bolder;

			span {
				background-color: #7460f42d;
				border-radius: 5px;
				padding: 2.5px 7.5px;
				font-size: 1.2rem;
			}
		}
	}

	.memberships-container {
		display: flex;
		flex-direction: column;
		gap: 20px;
		flex-grow: 1;

		.disclaimer-container {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			p {
				max-width: 300px;
				text-align: center;
				font-size: 1.4rem;
				margin: 0px;
				color: #153442;

				&.p1 {
					font-style: italic;
				}
			}
		}
	}
}
