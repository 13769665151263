.layout-main-container {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  background-color: #f8f7fa;

  > div {
    flex: 1;
    width: 100%;
    max-width: 1400px;
    margin: auto;
  }
}
