.inactive-membership-card {
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
	border-radius: 8px;
	padding: 20px 40px;
	background-color: #cccccc;

	* {
		margin: 0;
		color: #616161;
	}

	.title {

		h2 {
			font-size: 2.4rem;
			font-weight: bold;
		}

		p {
			font-size: 1.6rem;
			font-weight: 400;
		}
	}
	p.end-date {
		font-size: 1.6rem;
		font-weight: 600;
	}

	@media (max-width: 992px) {
		padding: 10px 20px;
		.title {
			h2 {
				font-size: 1.4rem;
				margin-bottom: 5px;
			}

			p {
				font-size: 1.2rem;
			}
		}

		p.end-date {
			font-size: 1.2rem;
		}
	}
}
