.login-form-container,
.signup-form-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 20px;
	align-items: center;
	flex: 1;

	a.restore-password-btn {
		font-size: 1.6rem;
		font-weight: 600;


	}

	img.logo {
		max-width: 200px;
	}

	form {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 10px;
		padding: 0px 40px;

		.form-row {
			width: 100%;

			label {
				font-size: 1.4rem;
				font-weight: 400;
				color: #465568;
			}

			.input-container {
				display: flex;
				justify-content: center;
				align-items: center;
				position: relative;
				border-radius: 5px;
				overflow: hidden;
				border: 1px solid #d8d6de;

				input,
				select,
				textarea {
					font-size: 1.6rem;
					border: none;
					background-color: transparent;
					flex: 1;
					border-radius: 5px;
					padding: 8px 45px;
					padding-right: 10px;
					outline-color: #465568;
					min-height: 40px;
				}

				textarea {
					padding: 10px;
					resize: none;
				}

				label {
					position: absolute;
					left: 10px;
					top: 50%;
					transform: translateY(-50%);

					svg {
						height: 24px;
						width: 24px;
						color: #465568;
					}
				}
			}

			.showPassword {
				cursor: pointer;
				position: absolute;
				right: 10px;
				top: 50%;
				transform: translateY(-50%);
			}

			.login-submit {
				width: 100%;
				padding: 7px 15px;
				font-size: 1.6rem;
				border-radius: 7px;
				border: none;
				background-color: #465568;
				color: #ffffff;
			}

			.login-submit.loading {
				background: none;
				background-color: #a6a6a6;
			}
		}
	}
}
