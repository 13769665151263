.purchase-steps {
	display: flex;
	padding: 20px;
	gap: 20px;

	.plans-container {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		gap: 20px;

		.promomix-container {
			padding: 20px;
			border-radius: 7px;
			cursor: pointer;

			p {
				font-size: 1.6rem;
			}
		}

		.cart-container {
			* {
				margin: 0;
			}

			.header {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 20px;

				h3 {
					font-size: 1.6rem;
				}
			}

			.items-container {
				display: flex;
				flex-direction: column;
				gap: 10px;

				.item-card {
					border: 1px solid #d7d7d7;
					border-radius: 7px;

					display: flex;
					align-items: center;
					justify-content: flex-start;
					position: relative;
					padding: 10px;
					gap: 20px;

					.btn.close {
						position: absolute;
						color: #000000;
						font-size: 2rem;
						right: 0px;
						top: 0px;
					}

					.image-container {
						width: 160px;
						height: 80px;
						svg, img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
						img {
							object-fit: contain;
						}
					}

					.class-info {
						h3 {
							font-size: 1.6rem;
						}

						.quantity-container {
							display: flex;
							flex-direction: column;
							gap: 10px;

							.quantities-container {
								display: flex;
								justify-content: flex-start;
								flex-wrap: wrap;
								align-items: flex-start;
								gap: 10px;

								.pack-container {
									cursor: pointer;
									display: flex;
									flex-direction: column;
									gap: 5px;
									justify-content: center;
									align-items: center;
									background-color: #d4d4d4;
									border: 2px solid #8e8e8e;
									border-radius: 10px;
									padding: 5px 20px;

									h4 {
										font-size: 1.4rem;
										font-weight: bolder;
										opacity: 0.5;
										text-align: center;
									}

									h5 {
										font-size: 1.2rem;
										font-weight: bolder;
										opacity: 0.5;
										text-align: center;
									}

									&.active {
										background-color: #eb787a;
										border-color: #ba6062;

										h4,
										h5 {
											color: #000000;
											opacity: 1;
										}
									}

									@media (max-width: 600px) {
										width: 100%;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.summary-container {
		min-width: 30%;
		height: fit-content;

		* {
			margin: 0;
		}

		h3 {
			font-size: 1.6rem;
		}

		p {
			font-size: 1.4rem;
		}

		.summary-table {
			border-radius: 7px;
			border: 1px solid #d7d7d7;

			> div {
				padding: 20px;
				border-bottom: 1px solid #d7d7d7;

				&.total {
					border: none;
				}
			}
		}
	}

	@media (max-width: 992px) {
		flex-direction: column;
	}
}

.lesson-selector-container {
	.lesson-item {
		.lesson-image-container {
			width: 200px;
			height: 100px;
			svg, img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			img {
				object-fit: contain;
			}
		}
	}
}
