.membership-card-component {
	width: 100%;
	border-radius: 7px;
	border: 4px solid #dfdfdf;
	padding: 20px;
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	gap: 10px;

	.membership-information-row {
		display: flex;
		flex-wrap: wrap;
		.memberhsip-information-container,
		.membership-progress-container {
			padding: 10px;
			min-width: 300px;
			width: 50%;
		}

		.memberhsip-information-container {
			display: flex;
			flex-direction: column;
			gap: 20px;

			.c-row {
				display: flex;
				flex-direction: column;
				gap: 5px;
				* {
					margin: 0;
					padding: 0;
				}
				h2 {
					font-size: 1.4rem;
					color: #74707d;
				}

				h3 {
					font-size: 1.4rem;
					color: #153442;
					.highlight {
						color: #ffac4a;
					}
				}

				h4 {
					font-size: 1.3rem;
					color: #74707d;

					.duedate {
						font-size: 1rem;
						border-radius: 5px;
						background-color: #ff00002d;
						color: red;
						padding: 3px;
					}
				}

				.counts-container {
					display: flex;
					gap: 10px;
					flex-wrap: wrap;

					.counter-container {
						border-radius: 5px;
						padding: 3px 6px;

						p {
							font-size: 1.2rem;
							font-weight: bolder;
						}

						&.scheduled {
							background-color: #ffab4a2d;

							p {
								color: #ffac4a;
							}
						}

						&.cancelled {
							background-color: #8080802d;

							p {
								color: grey;
							}
						}

						&.available {
							background-color: #ee62f42d;

							p {
								color: #ee62f4;
							}
						}
					}
				}
			}
		}
	}

	.membership-progress-container {
		display: flex;
		flex-direction: column;
		gap: 10px;

		.warning {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #ffac4a2d;
			border-radius: 5px;

			h4 {
				font-size: 1.2rem;
				color: #ffac4a;
				margin: 0;
				font-weight: bolder;
			}
		}
	}
	.c-row {
		&.footer {
			padding: 0px 10px;
		}
	}

	@media (max-width: 992px) {
		padding: 10px 30px;

		.membership-information-row {
			.memberhsip-information-container {
				padding: 0px;
				.c-row {
					h3 {
						margin-bottom: 5px;
						font-size: 1.6rem!important;
					}

					h4 {
						font-size: 1.4rem!important;
					}
				}
			}
		}
	}
}
