.user-navbar-main-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;

  a {
    background-color: #ffffff;
    border-radius: 7px;
    padding: 5px 10px;
    width: 170px;
    color: #000000;
    font-size: 1.6rem;
    text-align: center;
    order: 2;

    &.isActive {
      background-color: #eb787a;
      color: #ffffff;
    }

    @media (max-width: 991px) {
      order: 2;

     
    }
  }

  @media (max-width: 991px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0;
    height: 34px;
    overflow: hidden;

    &.open {
      height: fit-content;
    }
    a {
      width: 100%;
      border-radius: 0;
      box-shadow: none;
      border-bottom: 2px solid #0000001a;
    }
  }
}
