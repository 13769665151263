.terms-conditions-modal {
  .modal-dialog {
    .modal-content {
      .modal-body {
        display: flex;
        flex-direction: column;
        p, a {
          font-size: 1.6rem;
        }

        a {
          margin: auto;
          text-align: center;
          text-decoration: underline;
        }
      }
    }
  }
}