$spacer: 1rem;
$spacers: (
	0: 0,
	1: $spacer * 0.5,
	2: $spacer,
	3: $spacer * 1.5,
	4: $spacer * 2,
	5: $spacer * 2.5,
);

$font-size-base: 1rem;
$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-bolder: bolder;

$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

$font-sizes: (
	1: $h1-font-size,
	2: $h2-font-size,
	3: $h3-font-size,
	4: $h4-font-size,
	5: $h5-font-size,
	6: $h6-font-size,
	12: 1.2rem,
	14: 1.4rem,
	16: 1.6rem,
	18: 1.8rem,
	20: 2rem,
	22: 2.2rem,
	24: 2.4rem,
	26: 2.6rem,
	28: 2.8rem,
	30: 3rem,
	32: 3.2rem,
);
