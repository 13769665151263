.membership-selector-main-container {
	display: flex;
	gap: 10px;
	width: 100%;
	flex-wrap: wrap;
	align-items: center;

	div.membership-option {
		padding: 5px 10px;
		text-align: center;
		gap: 20px;
		border-radius: 12px;
		border: 1px solid black;

		> * {
			user-select: none;
		}

		&.active {
			background: #7367f0;
			color: #ffffff;
			border-color: #7367f0;
		}
	}

	@media (max-width: 992px) {
		justify-content: center;
	}
}
