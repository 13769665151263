.signup-form-container {
	p.note {
		font-size: 1.6rem;
	}

	span.required {
		font-size: 2rem;
		font-weight: bolder;
	}

	form {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: auto;
		grid-gap: 20px;
		align-items: end;

		& > :last-child,
		> .custom-message,
		> .errors {
			grid-column: span 2;
		}

		.errors {
			p {
				font-size: 1.6rem;
				color: red;
				margin: 0px;
			}
		}

		.terms {
			display: flex;
			justify-content: start;
			align-items: center;

			p,
			a {
				font-size: 1.4rem;
				margin: 0px;
			}

			input[type="checkbox"] {
				margin-right: 10px;
				flex: 0;
				// bigger checkbox
				transform: scale(1.5);
				margin-left: 3px;
			}
		}

		// customize input border color when it is invalid
		.form-row {
			label {
				user-select: none;
			}
			.input-container {
				overflow: visible;

				input[aria-invalid="true"],
				select[aria-invalid="true"] {
					border: 1px solid red;
					background-color: #ffe6e6; // Opcional: fondo rojo claro para destacar
				}

				.required-tooltip {
					display: none;
					position: absolute;
					top: 50%;
					left: 100%;
					transform: translate(10px, -50%);
					background-color: #153442;
					color: white;
					padding: 5px;
					border-radius: 6px;
					font-size: 1.4rem;
					z-index: 1;
					border: 1px solid #153442;

					&::before {
						content: "";
						position: absolute;
						top: 50%;
						right: 100%;
						transform: translateY(-50%);
						border-width: 8px;
						border-style: solid;
						border-color: transparent #153442 transparent transparent;
					}
				}

				input:focus + .required-tooltip,
				select:focus + .required-tooltip {
					display: block;
				}
			}
		}
	}

	.buttons-container {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.primary {
			margin-left: auto;
		}
	}
}

@media (max-width: 768px) {
	.signup-form-container form {
		grid-template-columns: 1fr;
		padding: 10px;

		& > :last-child,
		> .custom-message,
		> .errors {
			grid-column: 1;
		}

		.form-row {
			.input-container {
				.required-tooltip {
					top: 100%;
					left: 50%;
					transform: translate(-50%, 10px);

					&::before {
						top: unset;
						bottom: 100%;
						left: 50%;
						right: unset;
						transform: translateY(0px) translateX(-50%);
						border-color: transparent transparent #153442 transparent;
					}
				}
			}
			&.instagram {
				grid-row: 4;
			}

			&.blood {
				grid-row: 3;
			}
		}
	}
}
