.notification-container {
  position: absolute;
  right: 0px;
  top: 100%;
  background-color: white;
  width: 320px;
  border-radius: 5px;
  display: none;

  &.open {
    display: block;
  }

  @media (max-width: 450px) {
    transform: translate(20%, 0%);
    z-index: 1;
  }
}
