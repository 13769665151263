.auth-layout-main-container {
	width: 100%;
	display: flex;
	align-items: center;

	.portrait-image-container {
		flex: 1;
		img {
			width: 100%;
			height: 100vh;
			object-fit: cover;
		}
	}

	@media (max-width: 576px) {
		.portrait-image-container {
			display: none;
		}
	}
}
